export default function Privacy() {
  return (
    <>
      <h1>Privacy Policy for X Pro Browser Extension:</h1>
      <p>
        Thank you for choosing X Pro, a browser extension designed to enhance
        your Twitter experience by allowing you to add tags to Twitter users. We
        are committed to protecting your privacy and ensuring the security of
        your personal information. This Privacy Policy outlines how we collect,
        use, and safeguard your data when you use our extension.
      </p>
      <ol>
        <li>
          Information We Collect:
          <ul>
            <li>
              When you use X Pro, we collect the following information:
              <ul>
                <li>Your Twitter username</li>
                <li>Your Twitter user ID</li>
                <li>Tags added to Twitter users by you</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          How We Use Your Information:
          <ul>
            <li>
              We use the information collected solely for the purpose of
              providing you with the features and functionality of the X Pro
              extension.
            </li>
            <li>
              Your Twitter username and user ID are used to uniquely identify
              your account and associate the tags you create with your account.
            </li>
            <li>
              Tags added to Twitter users are stored in our secure database and
              associated with the respective user accounts.
            </li>
          </ul>
        </li>
        <li>
          Data Security:
          <ul>
            <li>
              We take appropriate measures to protect the security of your
              personal information and prevent unauthorized access, disclosure,
              alteration, or destruction.
            </li>
            <li>
              Your data is stored securely in our database, and access is
              restricted to authorized personnel only.
            </li>
            <li>
              We do not share your personal information with any third parties
              or companies.
            </li>
          </ul>
        </li>
        <li>
          Cookie Usage:
          <ul>
            <li>
              X Pro uses a JWT cookie for authentication purposes only. This
              cookie is generated and transferred to your browser window after
              you log in with your Twitter account. The cookie is then stored in
              your Chrome storage to maintain your session.
            </li>
          </ul>
        </li>
        <li>
          Data Retention:
          <ul>
            <li>
              We retain your personal information only for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy or as
              required by law.
            </li>
            <li>
              You can delete your account and associated data at any time by
              clicking on "Delete Account" button on the extension popup.
            </li>
          </ul>
        </li>
        <li>
          Third-Party Services:
          <ul>
            <li>
              X Pro does not integrate with any third-party services or
              platforms. We do not track your usage of Twitter or any other
              browser activity.
            </li>
          </ul>
        </li>
        <li>
          Changes to Privacy Policy:
          <ul>
            <li>
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be reflected on this page, and we
              encourage you to review this Privacy Policy periodically.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        By using the X Pro extension, you consent to the collection and use of
        your information as outlined in this Privacy Policy. If you have any
        questions or concerns about our Privacy Policy or data practices, please
        contact us at techtulpsolutions[at]gmail[dot]com.
      </p>
      <p>Last Updated: 13/04/2024</p>
      <p>TechTulp Solutions</p>
      <p>
        <a href={"/"}>Back to Home</a>
      </p>
    </>
  );
}
